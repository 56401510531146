import React from "react";
import nft1 from '../../Assets/images/001.mp4'
import nft2 from "../../Assets/images/002.mp4";
import nft3 from "../../Assets/images/003.mp4";
import nft4 from "../../Assets/images/004.mp4";
import nft5 from "../../Assets/images/005.mp4";
import nft6 from "../../Assets/images/006.mp4";
import nft7 from "../../Assets/images/007.mp4";
import nft8 from "../../Assets/images/008.mp4";
import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";
import ReactPlayer from 'react-player'

const Nftsec = () => {
  const carouselRef = React.useRef();
  const responsive = {
    0: { items: 1 },
    568: { items: 2 },
    1024: { items: 3 },
  };
  const items = [
    <div className="item">
      <div className="plancard">
      <ReactPlayer
                                    className='react-player'
                                    url={nft1}
                                    width='auto'
                                    height='auto'
                                    muted
                                    loop
                                    playing="true"
                                    playsinline="true"
                                />
      </div>
    </div>,
    <div className="item">
      <div className="plancard">
      <ReactPlayer
                                    className='react-player'
                                    url={nft2}
                                    width='auto'
                                    height='auto'
                                    muted
                                    loop
                                    playing="true"
                                    playsinline="true"
                                />
      </div>
    </div>,
    <div className="item">
      <div className="plancard">
      <ReactPlayer
                                    className='react-player'
                                    url={nft3}
                                    width='auto'
                                    height='auto'
                                    muted
                                    loop
                                    playing="true"
                                    playsinline="true"
                                />
      </div>
    </div>,
    <div className="item">
      <div className="plancard">
      <ReactPlayer
                                    className='react-player'
                                    url={nft4}
                                    width='auto'
                                    height='auto'
                                    muted
                                    loop
                                    playing="true"
                                    playsinline="true"
                                />
      </div>
    </div>,
    <div className="item">
      <div className="plancard">
      <ReactPlayer
                                    className='react-player'
                                    url={nft5}
                                    width='auto'
                                    height='auto'
                                    muted
                                    loop
                                    playing="true"
                                    playsinline="true"
                                />
      </div>
    </div>,
    <div className="item">
      <div className="plancard">
      <ReactPlayer
                                    className='react-player'
                                    url={nft6}
                                    width='auto'
                                    height='auto'
                                    muted
                                    loop
                                    playing="true"
                                    playsinline="true"
                                />
      </div>
    </div>,
    <div className="item">
      <div className="plancard">
      <ReactPlayer
                                    className='react-player'
                                    url={nft7}
                                    width='auto'
                                    height='auto'
                                    muted
                                    loop
                                    playing="true"
                                    playsinline="true"
                                />
      </div> 
    </div>,
    <div className="item">
      <div className="plancard">
      <ReactPlayer
                                    className='react-player'
                                    url={nft8}
                                    width='auto'
                                    height='auto'
                                    muted
                                    loop
                                    playing="true"
                                    playsinline="true"
                                />
      </div>
    </div>
  ];
  return (
    <section className="plan-area" id="planopportunity">
      {/* <img className="planbg" src={background} alt="" /> */}

      <div className="container">
      <div data-aos="fade-up" data-aos-duration="3000" className="vx_section-title text-start mb-4">
                                <div className="vx_section-title text-left mb-60">
                                    <span className="vx_sub-title vx_animate-text nfttittle "><span>NFT's</span></span>
                                </div>
                               
                            </div>
        <div className="plantittlesection">
          <div className="plantitle">
            <h2>
              {" "}
              
Discover Unique  <span>NFT's</span>{" "}



            </h2>
            <p>VitnixX isn't just about gaming; we're a gateway to a world of unique digital collectibles. Explore our NFT marketplace, and engage in a vibrant community of collectors. Discover the future of digital ownership and creativity with VitnixX NFTs.</p>
          </div>
          <div className="planbutton">
            <div
              class="button-prev"
              onClick={() => carouselRef.current.slidePrev()}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                class="icon"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M19.5 12h-15m0 0l6.75 6.75M4.5 12l6.75-6.75"
                />
              </svg>
            </div>
            <div
              class="button-next"
              onClick={() => carouselRef.current.slideNext()}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                class="icon"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M4.5 12h15m0 0l-6.75-6.75M19.5 12l-6.75 6.75"
                />
              </svg>
            </div>
          </div>
        </div>

        <div className="plancardsection">
          <AliceCarousel
            ref={carouselRef}
            mouseTracking
            items={items}
            responsive={responsive}
            controlsStrategy="responsive"
            dotsdi
            buttonsDisabled={false}
            keyboardNavigation={false}
          />
        </div>
        {/* <div className="text-center">
          <a href="javascript:void(0);" className="vx_border-button">
            <span>Explore More</span>
          </a>
        </div> */}
      </div>
    </section>
  );
};

export default Nftsec;
